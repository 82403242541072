import React from 'react';
import { useGetGatewayEventsQuery } from '../../app/services/appApi';
import { IGatewayEventRecord } from './gatewayTypes';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Alert, AlertTitle } from '@mui/material';

export default function GatewayEventList() {
    const { data, isError, isLoading } = useGetGatewayEventsQuery(undefined, { pollingInterval: 1000 });

    if (isLoading) {
        return <CircularProgress />;
    }

    if (isError) {
        return <Typography color="error">Errore durante il caricamento degli eventi</Typography>;
    }

    if (!data || data.results.length === 0) {
        return <Alert severity="info">
            <Typography>
                <AlertTitle><b>Nessun gateway connesso</b></AlertTitle>
                Sincronizza i dati della tua azienda con Typelens, migliorando la tua esperienza di utilizzo.
                <br />Il Gateway di Typelens ti permette di inserire i dati direttamente nel tuo gestionale senza intervento manuale.
                <br />Contatta il supporto per maggiori informazioni.
            </Typography>
        </Alert>
    }

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Evento</TableCell>
                        <TableCell>Timestamp</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.results.map((event: IGatewayEventRecord) => (
                        <TableRow key={event.id}>
                            <TableCell>{event.id}</TableCell>
                            <TableCell>{event.status}</TableCell>
                            <TableCell>{event.created_at}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}