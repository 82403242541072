import React from "react";
import { withAuth } from "../auth/authWrappers";
import { useTranslation } from "react-i18next";
import LanguageSelect from "./LanguageSelect";
import { Stack, Typography } from "@mui/material";
import GatewayEventList from "../gateway/GatewayEventList";
import LanguageIcon from '@mui/icons-material/Language';
import RouterIcon from '@mui/icons-material/Router';
const SettingsPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Stack justifyContent="space-between" direction="column" spacing={2}>
            <Typography variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
                <b>{t('Settings')}</b>
            </Typography>

            <Stack spacing={3}>
                <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
                    <LanguageIcon />
                    <Typography variant="body1">{t('Lingua')}</Typography>
                </Stack>
                <LanguageSelect />
                <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
                    <RouterIcon />
                    <Typography variant="body1">{t('Gateway')}</Typography>
                </Stack>
                <GatewayEventList />
            </Stack>
        </Stack>
    );
};

export default withAuth(SettingsPage);