import React from "react";
import useAuthContext from "./AuthContext";
import { Navigate, useLocation } from "react-router-dom";

// Create an enum of allowed roles
// the enum must match order of hierarchy
// to allow minimum role access
export enum Role {
  User = "user",
}

interface IWithAuthProps {
  allowedRoles: Role[];

  // Add any other props that come with the component
  // you want to wrap
  [key: string]: any;

}

const withAuth = <P extends object>(Component: React.ComponentType<P>) => {
  const WithAuth = ({ allowedRoles, ...props }: IWithAuthProps) => {
    const { user } = useAuthContext();
    const location = useLocation();

    // Check if user has the necessary permissions
    // to access the component
    // const hasPermission = (allowedRoles: Role[], userRole: Role) => {
    //   const userRoleIndex = allowedRoles.indexOf(userRole);
    //   return userRoleIndex !== -1;
    // };

    // If user is not logged in, redirect to login page
    if (user === undefined) {
      // Render a loading screen here
      return <div>Loading...</div>;
    }

    // If user is not logged in, redirect to login page
    if (user === null) {
      // enqueueSnackbar("You must be logged in to view this page", {
      //   variant: "error",
      // });
      return <Navigate replace to="/login" />;
    }

    // If user is deactivated, redirect to / deactivated page
    if (user.organization.is_deactivated && location.pathname !== "/deactivated") {
      return <Navigate replace to="/deactivated" />;
    }
    // If user is not and the path is /deactivated, redirect to home page
    if (!user.organization.is_deactivated && location.pathname === "/deactivated") {
      return <Navigate replace to="/" />;
    }


    // If user does not have the necessary permissions
    // to access the component, redirect to home page
    // if (!hasPermission(allowedRoles, token.role)) {
    //   return <Redirect to="/" />;
    // }

    // If user has the necessary permissions
    // to access the component, render it
    return <Component {...(props as P)} />;
  };

  return WithAuth;
};

interface IWithNoAuthProps { }

const withNoAuth = <P extends object>(Component: React.ComponentType<P>) => {
  const WithNoAuth = ({ ...props }: IWithNoAuthProps) => {
    const { user } = useAuthContext();

    // If user is not logged in, render the component
    if (user === undefined) {
      // Render a loading screen here
      return <div>Loading...</div>;
    }

    // If user is logged in, redirect to home page
    if (user !== null) {
      // enqueueSnackbar("You are already logged in", {
      //   variant: "info",
      // });
      return <Navigate replace to="/" />;
    }

    // Render the wrapped component
    return <Component {...(props as P)} />;
  };

  return WithNoAuth;
};

export { withAuth, withNoAuth };
