import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useDevSettings } from './DevSettings';
import FormGroup from '@mui/material/FormGroup';

const DevSettingsForm: React.FC = () => {
    const { settings, updateSetting } = useDevSettings();

    const handleToggle = (key: keyof typeof settings) => (event: React.ChangeEvent<HTMLInputElement>) => {
        updateSetting(key, event.target.checked);
    };

    return (
        <div>
            <FormGroup title='Dev settings'>
                {Object.keys(settings).map((key) => (
                    <FormControlLabel
                        key={key}
                        control={
                            <Switch
                                checked={settings[key as keyof typeof settings]}
                                onChange={handleToggle(key as keyof typeof settings)}
                                name={key}
                                color="primary"
                            />
                        }
                        label={key}
                    />
                ))}
            </FormGroup>
        </div>
    );
};

export default DevSettingsForm;