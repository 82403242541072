import { Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface EmailDetailAccordionProps {
    id: string;
    label: React.ReactNode;
    children: React.ReactNode;
    defaultExpanded?: boolean;
}

export const EmailDetailAccordion: React.FC<EmailDetailAccordionProps> = ({ id, label, children, defaultExpanded = false }) => (
    <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${id}-content`} id={`${id}-header`}>
            <Typography>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {children}
        </AccordionDetails>
    </Accordion>
);