// BenchmarkListPage.tsx

import React, { useEffect, useState } from "react";
import {
    useGetBenchmarkReportsQuery,
    useDeleteBenchmarkReportMutation,
    useCreateBenchmarkMutation,
} from "../../app/services/appApi";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { IBenchmarkReportListItem } from "./benchmarkTypes";
import { Button, Typography, TextField } from "@mui/material";
import Stack from "@mui/system/Stack";
import { withAuth } from "../auth/authWrappers";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';

const BenchmarkListPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });

    const { data, isLoading } = useGetBenchmarkReportsQuery({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        search: "",
    });

    const [createBenchmark] = useCreateBenchmarkMutation();
    const [emailIds, setEmailIds] = useState("");

    const handleCreateBenchmark = async () => {
        const ids = emailIds.split(",").map(id => parseInt(id.trim(), 10)).filter(id => !isNaN(id));
        if (ids.length === 0) {
            enqueueSnackbar(t("Please enter valid email IDs"), { variant: "error" });
            return;
        }
        try {
            await createBenchmark({ ids });
            enqueueSnackbar(t("Benchmark created successfully"), { variant: "success" });
            setEmailIds("");
        } catch (error) {
            enqueueSnackbar(t("Failed to create benchmark"), { variant: "error" });
        }
    };

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", width: 70 },
        { field: "app_version", headerName: "App Version", width: 130 },
        { field: "overall_accuracy", headerName: "Overall Accuracy", width: 150, valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%` },
        { field: "created_at", headerName: "Created At", width: 200 },
        {
            field: "actions",
            headerName: "Actions",
            width: 120,
            //@ts-ignore-next-line

            renderCell: (params: GridValueGetterParams) => (
                <>
                    <IconButton onClick={() => navigate(`/benchmark/${params.row.id}`)}>
                        <VisibilityIcon />
                    </IconButton>
                    <BenchmarkDeleteBtn benchmarkId={params.row.id} />
                </>
            ),
        },
    ];

    return (
        <>
            <Stack gap={2} direction="row" sx={{ mb: 2 }}>
                <Typography variant="h5" component="h1" noWrap sx={{ flexGrow: 1 }}>
                    <b>{t("Benchmark Reports")}</b>
                </Typography>
            </Stack>

            <Stack gap={2} direction="row" sx={{ mb: 2 }}>
                <TextField
                    label={t("Email IDs (comma-separated)")}
                    variant="outlined"
                    size="small"
                    value={emailIds}
                    onChange={(e) => setEmailIds(e.target.value)}
                    sx={{ flexGrow: 1 }}
                />
                <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    onClick={handleCreateBenchmark}
                >
                    {t("Create Benchmark")}
                </Button>
            </Stack>

            <DataGrid
                disableRowSelectionOnClick
                rows={data?.results || []}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                }}
                pageSizeOptions={[20, 50, 100]}
                sx={{ height: "80vh", my: 2 }}
                loading={isLoading}
                paginationMode="server"
                rowCount={data?.count || 0}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
        </>
    );
};

const BenchmarkDeleteBtn: React.FC<{ benchmarkId: number }> = ({ benchmarkId }) => {
    const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteBenchmarkReportMutation();
    const { t } = useTranslation();

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar(t("Benchmark report deleted"), { variant: "success" });
        }
        if (isError) {
            enqueueSnackbar(t("Failed to delete benchmark report"), { variant: "error" });
        }
    }, [isSuccess, isError, t]);

    return (
        <IconButton
            onClick={() => triggerDelete(benchmarkId)}
            color="error"
            disabled={isLoading}
        >
            <DeleteOutlineOutlinedIcon />
        </IconButton>
    );
};

export default withAuth(BenchmarkListPage);