import { Alert, Typography, AlertTitle } from '@mui/material'
import React from 'react'


export default function RegisteredEmailAlert(props: React.ComponentProps<typeof Alert>) {
    return (
        <Alert severity="info" {...props}>
            <Typography>
                <AlertTitle><b>Dati inviati a gestionale</b></AlertTitle>
                Questi dati sono stati inviati correttamente al gestionale e non possono essere modificati
            </Typography>
        </Alert>
    )
}
