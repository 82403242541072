import React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useAuthContext from "./AuthContext";
import { withAuth } from './authWrappers';
import { Stack } from '@mui/material';

const UserDeactivatedPage: React.FC = () => {
    const { logout } = useAuthContext();

    const handleContactUs = () => {
        // Implementa la logica per contattare il supporto
        console.log("Contatta il supporto");
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 8,
                px: 3,
            }}
        >
            <ErrorOutlineIcon sx={{ fontSize: 64, color: 'warning.main', mb: 2 }} />
            <Typography variant="h4" align="center" gutterBottom>
                Account Disattivato
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 4, maxWidth: 600 }}>
                Ci dispiace, ma la tua licenza risulta scaduta o il tuo account è stato temporaneamente disattivato per inattività.
                Per riattivare il servizio, ti preghiamo di contattarci con il pulsante qui sotto o scrivendo a <i>info@mastranet.ai</i>
            </Typography>
            <Stack gap={2}>
                <Button
                    component="a"
                    href="https://www.mastranet.ai/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="contained"
                    color="primary"
                    onClick={handleContactUs}
                >
                    Contattaci
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={logout}
                >
                    Logout
                </Button>
            </Stack>
        </Box>
    );
};

export default withAuth(UserDeactivatedPage);
