import React, { } from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Stack } from "@mui/system";
import { useDebugInfoQuery } from "../../app/services/appApi";
import DevSettingsForm from "./devSettings/DevSettingsForm";

const DeveloperPage: React.FC = () => {
  const { data } = useDebugInfoQuery();

  return (
    <>
      <Typography variant="h4">Debug page</Typography>
      <Stack spacing={2}>
        <Typography variant="h6">Notification stack</Typography>
        <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
          {["error", "warning", "info", "success"].map((variant: any) => (
            <Button
              variant="contained"
              color={variant as any}
              key={variant}
              onClick={() => enqueueSnackbar("Hello world!", { variant })}
            >
              Show {variant} snackbar
            </Button>
          ))}
        </Stack>

        <Typography variant="h6">Frontend info</Typography>
        <Typography variant="body1" component="p">
          Version: {process.env.REACT_APP_VERSION}
          <br />
          Environment: {process.env.NODE_ENV}
        </Typography>
        <Typography variant="h6">Backend info</Typography>
        <Typography variant="body1" component="p">
          {JSON.stringify(data)}
        </Typography>

        <Typography variant="h6">Crash frontend</Typography>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            throw new Error("Crash frontend");
          }}
        >
          Crash frontend
        </Button>

        <Typography variant="h6">Dev settings</Typography>
        <DevSettingsForm />

      </Stack>
    </>
  );
};

export default DeveloperPage;
