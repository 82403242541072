import React from 'react';
import { Controller, Control, ValidationRule } from "react-hook-form";
import { TextField, MenuItem, FormHelperText } from "@mui/material";
import { UISchemaField } from './dynamicUiTypes';
import ServerSideSearchSelect2 from '../../input/select/ServerSideSearchSelect2';
import { useLazyGetAutocompleteQuery } from "../../../app/services/appApi";
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { FormControl, InputLabel, Select } from "@mui/material";


interface FieldRendererProps {
    fieldName: string;
    fieldSchema: UISchemaField;
    control: Control<any>;
    index?: number;
    disabled?: boolean;
}

const FieldRenderer: React.FC<FieldRendererProps> = ({ fieldName, fieldSchema, control, index, disabled }) => {
    const name = index !== undefined ? `items[${index}].${fieldName}` : fieldName;
    const validation: Record<string, ValidationRule<any>> = {};

    if (fieldSchema.validation) {
        if (fieldSchema.validation.required) {
            validation.required = { value: true, message: `${fieldSchema.label} è obbligatorio.` };
        }
        if (fieldSchema.validation.min !== undefined) {
            validation.min = { value: fieldSchema.validation.min, message: `${fieldSchema.label} deve essere almeno ${fieldSchema.validation.min}.` };
        }
        if (fieldSchema.validation.max !== undefined) {
            validation.max = { value: fieldSchema.validation.max, message: `${fieldSchema.label} deve essere al massimo ${fieldSchema.validation.max}.` };
        }
        if (fieldSchema.validation.pattern) {
            validation.pattern = { value: new RegExp(fieldSchema.validation.pattern), message: 'Formato non valido.' };
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={validation}
            disabled={disabled}
            render={({ field, fieldState: { error } }) => {
                switch (fieldSchema.type) {
                    case 'string':
                        return (
                            <TextField
                                {...field}
                                value={field.value || ""}
                                label={fieldSchema.label}
                                fullWidth
                                size="small"
                                error={!!error}
                                helperText={error?.message}
                            />
                        );
                    case 'multiline':
                        return (
                            <TextField
                                {...field}
                                value={field.value || ""}
                                label={fieldSchema.label}
                                fullWidth
                                size="small"
                                multiline
                                rows={3}
                                error={!!error}
                                helperText={error?.message}
                            />
                        );
                    case 'int':
                        return (
                            <TextField
                                {...field}
                                value={field.value || ""}
                                label={fieldSchema.label}
                                fullWidth
                                size="small"
                                type="number"
                                inputProps={{ step: 1 }}
                                error={!!error}
                                helperText={error?.message}
                            />
                        );
                    case 'float':
                        return (
                            <TextField
                                {...field}
                                value={field.value || ""}
                                label={fieldSchema.label}
                                fullWidth
                                size="small"
                                type="number"
                                inputProps={{ step: 0.01 }}
                                error={!!error}
                                helperText={error?.message}
                            />
                        );
                    case 'date':
                        return <DatePicker
                            {...field}
                            value={moment(field.value)}
                            label={fieldSchema.label}
                            formatDensity="spacious"
                            onError={(newError) => console.log(newError)}
                            slotProps={{
                                textField: {
                                    helperText: error?.message,
                                    // error: !!error,
                                    size: "small",
                                },
                            }}
                        />;
                    case 'search':
                        return (
                            <ServerSideSearchSelect2
                                {...field}
                                label={fieldSchema.label}
                                searchModel={fieldSchema.searchModel}
                                lazyQuery={useLazyGetAutocompleteQuery}
                                inputProps={{
                                    placeholder: "Digita per cercare...",
                                    size: "small",
                                    error: !!error,
                                    helperText: error?.message
                                }}
                            />);
                    case 'enum':
                        return (
                            <FormControl fullWidth size="small" error={!!error} {...field}>
                                <InputLabel id={`${name}-label`}>{fieldSchema.label}</InputLabel>
                                <Select
                                    {...field}
                                    labelId={`${name}-label`}
                                    id={`${name}-select`}
                                    label={fieldSchema.label}
                                >
                                    {fieldSchema.options?.map(({ value, label }) => (
                                        <MenuItem key={value} value={value}>{label}</MenuItem>
                                    ))}
                                </Select>
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                            </FormControl>
                        );
                    default:
                        return (
                            <FormHelperText error>
                                Error: Field type not supported
                            </FormHelperText>
                        );
                }
            }}
        />
    );
};

export default FieldRenderer;