import React, { useEffect, useState } from "react";
import {
  useGetContactsQuery,
  useDeleteContactMutation,
} from "../../app/services/appApi";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { IContactRecord } from "./contactTypes";
import { Button, Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import { withAuth } from "../auth/authWrappers";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContactFormDialog from "./ContactFormDialog";
import AddIcon from '@mui/icons-material/Add';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ContactSearchBar from "./ContactSearchBar";
import { useNavigate } from "react-router-dom";

const ContactListPage = () => {

  const navigate = useNavigate();

  const handleUploadClick = () => {
    navigate("/contacts/upload");
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetContactsQuery(
    {
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search: searchValue,
    }
  );


  const columns: GridColDef[] = [
    {
      field: "code", headerName: "Codice", width: 180

    },
    {
      field: "name",
      headerName: "Nome",
      // Grow the column width
      flex: 1,
    },
    {
      field: "edit", headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => (
        <ContactFormDialog contactId={params.row.id} />
      ),
    },
    {
      field: "delete", headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => (
        <ContactDeleteBtn contactId={params.row.id} />
      ),
    },
  ];

  return (
    <>
      <Stack gap={2} direction="row" sx={{ mb: 2 }}>
        <Typography variant="h5" component="h1" noWrap sx={{ flexGrow: 1 }}>
          <b>Clienti</b>
        </Typography>
        <ContactSearchBar onSearch={setSearchValue} size="small" />
        <ContactFormDialog render={(props) => (
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            {...props}
          >
            Aggiungi
          </Button>
        )} />


        <Button
          startIcon={<UploadFileOutlinedIcon />}
          variant="outlined"
          color="primary"
          onClick={handleUploadClick}
        >
          Carica CSV
        </Button>
      </Stack >

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ height: "80vh", my: 2 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />

    </>
  );
}





const ContactDeleteBtn: React.FC<{ contactId: Pick<IContactRecord, "id"> }> = ({ contactId }) => {
  const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteContactMutation();
  const { t } = useTranslation();

  // Handle error and success notifications
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("Model deleted", { model: "Contact", count: 1 }), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("Model deletion failed", { model: "Contact", count: 1 }), { variant: "error" });
    }
  }, [isSuccess, isError, t]);


  return (
    <IconButton
      onClick={() => triggerDelete(contactId)}
      color="error"
      disabled={isLoading}
    >
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  )


}

export default withAuth(ContactListPage);
