import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export default function LoadingPlaceholder() {
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            gap={2}
            sx={{
                width: '100%',
                height: '75%',
            }}
        >
            <CircularProgress />
            <Typography variant="h6" component="h1" noWrap sx={{ ml: 2 }}>
                <b>Caricamento...</b>
            </Typography>
        </Stack>
    )
}
