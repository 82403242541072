import React from "react";
import { Box, Card, CardContent, Typography, Grid, Button, LinearProgress, styled, linearProgressClasses } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useGetPipelinesQuery } from "../../app/services/appApi";
import { useNavigate } from 'react-router-dom';
import { IPipelineRecord } from "./pipelineTypes";

const PipelineCard = ({ pipeline }: { pipeline: IPipelineRecord }) => {
  const navigate = useNavigate();
  const handleEditClick = (id: number) => {
    navigate(`/pipelines/${id}`);
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const { id, code, count_emails, count_emails_current_month, count_emails_last_24h, title, subtitle, ratelimit } = pipeline;

  const progressPercentage = Math.min(count_emails_current_month, 100);

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <ListAltIcon style={{ width: 36, height: 36, marginRight: 8 }} />
          <Typography variant="h4" component="div">
            {title}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {subtitle}
          </Typography>
          <Typography variant="body2">
            Codice: {code}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Totale Ordini: {count_emails}   | Mese corrente: {count_emails_current_month}/{ratelimit} |  24 ore: {count_emails_last_24h}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <BorderLinearProgress variant="determinate" value={progressPercentage} />
          </Typography>

          <Button variant="outlined" onClick={() => handleEditClick(id)}
            startIcon={<VisibilityIcon />}
            sx={{ width: '150px', height: '40px', marginTop: 'auto', alignSelf: 'flex-end' }}>
            Visualizza
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
}


const PipelineListPage = () => {
  const { data, isLoading } = useGetPipelinesQuery({});
  const rows = data?.results || [];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        {rows.map((pipeline) => (
          <PipelineCard key={pipeline.id} pipeline={pipeline} />
        ))}
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant="h4" component="div" sx={{ mb: 3 }}>
              </Typography>
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                sx={{ width: '130px', height: '40px', alignSelf: 'center' }}>
                AGGIUNGI
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PipelineListPage;
