import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export default function ErrorPlaceholder() {
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            gap={2}
            sx={{
                width: '100%',
                height: '75%',
            }}
        >
            <ErrorOutlineIcon color="error" sx={{ fontSize: 60 }} />
            <Typography variant="h6" component="h1">
                <b>Si è verificato un errore</b>
            </Typography>
            <Typography variant="body1" component="p">
                L'email non è stata trovata
            </Typography>
        </Stack>
    )
}
