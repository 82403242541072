import { useState, useEffect } from "react";
import { Alert, Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import { withAuth } from "../auth/authWrappers";
import { useDropzone } from 'react-dropzone';
import { useUploadBatchFileMutation } from "../../app/services/appApi";
import { enqueueSnackbar } from "notistack";
import AlertTitle from '@mui/material/AlertTitle';
import { LoadingButton } from "@mui/lab";
import UploadIcon from '@mui/icons-material/Upload';


interface BatchFileUploadPageProps {
  recordType: "products" | "contacts";
}

const BatchFileUploadPage = ({ recordType }: BatchFileUploadPageProps) => {

  const [file, setFile] = useState<File | null>(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0]);
    }
  });

  const recordName = recordType === 'contacts' ? 'clienti' : 'prodotti';

  const [uploadBatchFile, { isLoading, isError, isSuccess, data }] = useUploadBatchFileMutation();

  const handleUpload = () => {
    if (!file) {
      enqueueSnackbar("No file selected", { variant: 'error' });
      return;
    }

    uploadBatchFile({ file: file, recordType: recordType })
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('Errore nel caricamento del file. Solo file CSV sono consentiti.', { variant: 'error' });
    } else if (isSuccess) {
      enqueueSnackbar("File uploaded successfully", { variant: 'success' });
    }
  }, [isError, isSuccess]);

  return (
    <>
      <Stack justifyContent="space-between" direction="row">
        <Typography variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
          <b>Caricamento {recordName}</b>
        </Typography>
      </Stack>
      <Stack spacing={2} sx={{ mb: 2 }}>
        <Typography variant="body1" component="p">
          Puoi caricare un file CSV per aggiornare l'anagrafica dei {recordName}.<br />
          Il file deve contenere un record per ogni riga, con i campi separati da "," o ";" e deve avere l'intestazione con i nomi dei campi.<br />
          Il file deve contenere le seguenti colonne:
          {recordType === 'contacts' ? (
            <ul>
              <li>codice</li>
              <li>nome</li>
              {/* <li>piva</li> */}
              <li>indirizzo</li>
              <li>cap</li>
              <li>comune</li>
              <li>provincia</li>
              <li>nazione</li>
            </ul>
          ) : (
            <ul>
              <li>codice</li>
              <li>nome</li>
            </ul>)}
        </Typography>
        <Alert severity='warning'>
          <AlertTitle>Attezione</AlertTitle>
          Il caricamento del file sovrascriverà i {recordName} esistenti
        </Alert>
        {/* Upload drop zone */}
        {!data && !isSuccess &&
          (
            <div {...getRootProps()} style={{ border: '1px dashed gray', padding: '20px', textAlign: 'center' }}>
              <input {...getInputProps()} />
              {file ? <p>{file.name}</p>
                :
                <p>Trascina qui il file da caricare, oppure clicca per selezionare un file</p>
              }
            </div>
          )}
        {/* Error/Success message */}
        {isSuccess && data &&
          <Alert severity='success'>
            <AlertTitle>Caricamento completato</AlertTitle>
            I dati sono stati caricati correttamente
          </Alert>}
        {/* Upload button */}
        {!isSuccess && <LoadingButton
          startIcon={<UploadIcon />}
          loading={isLoading}
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!file || isLoading}>
          Upload
        </LoadingButton>}
      </Stack>
    </>
  );
}

export default withAuth(BatchFileUploadPage);