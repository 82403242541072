import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import useHotjar from "react-use-hotjar";

const usePageTracking = () => {
    let location = useLocation();
    const [initialized, setInitialized] = useState(false);
    const { initHotjar } = useHotjar();

    useEffect(() => {
        /** Executed only once to init Google Analytics */
        if (
            process.env.REACT_APP_GA_ENABLE === "true" &&
            process.env.REACT_APP_GA_MEASUREMENT_ID
        ) {
            console.log("Initializing GA...");
            ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
            setInitialized(true);
        }



    }, []);

    /** Init Hotjar website analytics and heatmapping tool */
    useEffect(() => {
        if (
            process.env.REACT_APP_HOTJAR_ENABLE === "true" &&
            process.env.REACT_APP_HOTJAR_SITE_ID
        ) {
            // disable logging in prod
            console.log("Initializing Hotjar...");
            const logger = process.env.NODE_ENV === "development" ? console.log : undefined;
            initHotjar(Number(process.env.REACT_APP_HOTJAR_SITE_ID), 6, true, logger);
        }
    }, [initHotjar]);

    useEffect(() => {
        /** Tracks every location change */
        if (initialized) {
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
        }
    }, [initialized, location]);
};

export default usePageTracking;
