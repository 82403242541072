import { useState } from "react";
import { useGetEmailsQuery } from "../../app/services/appApi";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRowSelectionModel,
  GridRowParams,
  GridActionsCellItem
} from "@mui/x-data-grid";
import { IEmailRecord } from "./emailTypes";
import { Tooltip, Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import EmailStatusChip from "./EmailStatusChip";
import { withAuth } from "../auth/authWrappers";
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import EmailCopyBtn from "./EmailCopyBtn";
import DownloadExcelBtn from "./toolbar/DownloadCsvBtn";
import BenchmarkBtn from "../benchmark/BenchmarkBtn";
import TimeAgo from 'react-timeago'
import { useDevSettings } from "../debug/devSettings/DevSettings";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TableRowActionsMenu from "./TableRowActionsMenu";
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';

function EmailListPage() {
  const navigate = useNavigate();
  const { settings } = useDevSettings();

  const handleEditClick = (emailId: number) => {
    navigate(`/email/${emailId}`);
  };

  const getEmailActions = (params: GridRowParams) => {
    const emailId = Number(params.id);

    const editBtn = params.row.status === 'PROCESSED';
    const reprocessBtn = params.row.status !== 'PROCESSING';
    const deleteBtn = params.row.status !== 'PROCESSING';

    const editMsg = editBtn ? 'Modifica' : 'Puoi modificare solo le email processate';

    return [
      <Tooltip title={editMsg} key="edit">
        <span>
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="actions"
            disabled={!editBtn}
            onClick={() => handleEditClick(emailId)}
          />
        </span>
      </Tooltip>,
      <TableRowActionsMenu
        emailId={emailId}
        reprocessBtn={reprocessBtn}
        deleteBtn={deleteBtn}
      />]
  }


  const baseColumns: GridColDef[] = [
    {
      field: "status",
      headerName: "Stato",
      width: 155,
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => (
        <EmailStatusChip
          level={params.value as IEmailRecord["status"]}
          variant="outlined"
        />
      ),
    },
    {
      field: "subject",
      headerName: "Oggetto",
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      //@ts-ignore-next-line
      getActions: getEmailActions,
    },
    {
      field: "is_certified",
      headerAlign: 'center',
      align: 'center',
      headerName: "",
      width: 100,
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => {
        const { is_saved, is_certified, is_registered } = params.row;
        let icon = null;
        let tooltipText = "";

        if (is_registered) {
          icon = (
            <>
              <DoneAllIcon color="primary" sx={{ opacity: 0.8 }} />
            </>
          );
          tooltipText = "Inviato";
        } else if (is_certified) {
          icon = (
            <>
              <DoneAllIcon color="action" sx={{ opacity: 0.6 }} />
            </>
          );
          tooltipText = "Certificato";
        } else if (is_saved) {
          icon = <DoneIcon color="action" sx={{ opacity: 0.6 }} />;
          tooltipText = "Salvato";
        }

        return icon ? (
          <Tooltip title={tooltipText}>
            <div style={{ display: 'flex', alignItems: 'center' }}>{icon}</div>
          </Tooltip>
        ) : null;
      },
    },
    {
      field: "created_at",
      headerName: "Ricezione",
      width: 150,
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => (
        <TimeAgo date={new Date(params.value as string)} />
      ),
    },

  ];

  const idColumn: GridColDef = {
    field: "id",
    headerName: "ID",
    width: 70,
    headerAlign: "center",
    align: "center",
  };

  const columns = settings.showIdColumn ? [idColumn, ...baseColumns] : baseColumns;

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const { data, isLoading } = useGetEmailsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
  }, { pollingInterval: 3000 });

  return (
    <>
      <style>
        {`
        .MuiDataGrid-row .actions {
          visibility: hidden;
        }
        .MuiDataGrid-row:hover .actions {
          visibility: visible;
        }
      `}
      </style>
      <Stack justifyContent="space-between" direction="row">
        <Typography variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
          <b>E-mail ricevute</b>
        </Typography>
        <EmailCopyBtn />
      </Stack>
      <DataGrid
        rows={data?.results || []}
        columns={columns}
        sx={{ height: "80vh", mt: 2 }}
        loading={isLoading}
        pageSizeOptions={[10, 20, 50]}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        isRowSelectable={(params: GridRowParams) => params.row.status === 'PROCESSED'}
        disableColumnMenu
        sortingMode="server"
      />
      <Stack justifyContent="space-between" direction="row" gap={2}>
        <DownloadExcelBtn ids={rowSelectionModel as number[]} />
        {settings.showBenchmark && <BenchmarkBtn ids={rowSelectionModel as number[]} />}
      </Stack>
    </>
  );
}

export default withAuth(EmailListPage);