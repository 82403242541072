import React, { useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { useParams } from 'react-router-dom';
import { withAuth } from "../auth/authWrappers";
import Stack from "@mui/system/Stack";
import { Typography } from "@mui/material";
import { useGetEmailQuery, usePatchEmailMutation } from "../../app/services/appApi";
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from "notistack";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailEditableFields from "./EmailEditableFields";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EmailExtractionInfo from "./EmailExtractionInfo";
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import EmailToolbar from "./toolbar/EmailToolbar";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import LoadingPlaceholder from "./LoadingPlaceholder";
import ErrorPlaceholder from "./ErrorPlaceholder";

function EmailDetailPage() {
    const { id } = useParams();


    const { data: email, isLoading, isFetching, isError } = useGetEmailQuery(Number(id), { skip: !id });

    const [patchEmail, { isLoading: isPatching, isSuccess: isPatchSuccess, isError: isPatchError }] = usePatchEmailMutation();

    const onSubmit = (data: any) => {
        patchEmail({ id: Number(id), body: { parsed_info: data } });
    };

    useEffect(() => {
        if (isPatchSuccess) {
            enqueueSnackbar('Dati aggiornati', { variant: 'success' });
        }
        if (isPatchError) {
            enqueueSnackbar('Errore durante il salvataggio', { variant: 'error' });
        }
    }, [isPatchSuccess, isPatchError]);

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
                <IconButton component={Link} sx={{ mr: 2 }} to="/">
                    <ArrowBackIcon />
                </IconButton>
                {/* For some reason, the "..." CSS text cutting does not work unless we set a fictitious width in px */}
                <Box sx={{ flexGrow: 1, mb: 2, width: "100px" }}>
                    <Typography variant="h5" component="h1" noWrap sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>
                        <b>
                            {email?.subject || "Caricamento email..."}
                        </b>
                    </Typography>
                </Box>
                {id && <EmailToolbar emailId={Number(id)} email={email} />}
            </Stack>
            <Allotment
                onDragEnd={(sizes) => {
                    localStorage.setItem("emailDetailSplit", JSON.stringify(sizes));
                }}
                defaultSizes={JSON.parse(localStorage.getItem("emailDetailSplit") || "[0.66,0.33]") as Array<number>}
            >
                <div style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                    <Paper>
                        {email?.attachments && email?.attachments[0]?.presigned_url && <iframe
                            title="email-attachment"
                            src={email.attachments[0].presigned_url}
                            width="100%"
                            height="1300px"
                        />}
                    </Paper>
                </div>
                <div style={{ width: "100%", height: "100%" }}>
                    {isLoading ? <LoadingPlaceholder />
                        : isError ? <ErrorPlaceholder />
                            : email && (<TabContext value={value}>
                                <Box
                                    sx={{
                                        // backgroundColor: "red",
                                        display: "flex",
                                        flexDirection: "column",
                                        // border: "3px solid black",
                                        height: "100%",
                                        px: 1
                                    }}
                                >
                                    {/* Tab headers */}
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="Dati estratti" value="1" />
                                            <Tab label="Avvisi" value="2" />
                                            <Tab label="Esporta codice" value="3" />
                                        </TabList>
                                    </Box>
                                    {/* Tab panel scrollable container */}
                                    <Box sx={{
                                        flexGrow: 1,
                                        overflowY: "auto",
                                        // p: 2,
                                        // backgroundColor: "yellow",
                                        // border: "2px solid black"
                                    }}>
                                        <TabPanel value="1" sx={{ height: "100%", p: 0 }}>
                                            <EmailEditableFields key={email.id} email={email} isLoading={isPatching || isFetching} onSubmit={onSubmit} />
                                        </TabPanel>
                                        <TabPanel value="2" sx={{ height: "100%", p: 0 }}><EmailExtractionInfo
                                            events={email.events}
                                        /></TabPanel>
                                        <TabPanel value="3" sx={{ p: 0 }}>
                                            <Box sx={{ p: 3 }}>
                                                <Typography variant="body1" component="p" sx={{ mb: 2 }}>
                                                    <JsonView src={email.parsed_info} collapsed={2} collapseStringMode="directly"
                                                        collapseStringsAfterLength={30} />
                                                </Typography>
                                            </Box>
                                        </TabPanel>

                                    </Box>


                                    {/* <Box sx={{ p: 2 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        <b>Ultimo aggiornamento:</b> {email.updated_at}
                                    </Typography>
                                </Box> */}
                                </Box>
                            </TabContext>
                            )

                    }

                </div>

            </Allotment >
        </ >
    );
}

export default withAuth(EmailDetailPage);