import React, { useEffect } from 'react'
import { Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRegisterEmailMutation } from "../../app/services/appApi";
import { enqueueSnackbar } from 'notistack';
import { IEmailRecord } from './emailTypes';

interface RegisterEmailBtnProps extends React.ComponentProps<typeof LoadingButton> {
    email: IEmailRecord
    disable?: boolean

}

function RegisterEmailBtn({ email, disable, ...props }: RegisterEmailBtnProps) {

    const [registerEmail, { isLoading, isSuccess, isError }] = useRegisterEmailMutation()

    const disabled = !email.is_certified || email.is_registered || disable
    const message = 'Invia i dati al ERP o Gestionale'

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar('Dati inviati con successo', { variant: 'success' })
        }
        if (isError) {
            enqueueSnackbar('Errore durante l\'invio dei dati', { variant: 'error' })
        }
    }, [isSuccess, isError])


    return (
        <Tooltip title={message}>
            <span>
                <LoadingButton
                    variant='contained'
                    color='primary'
                    startIcon={<SendIcon />} {...props}
                    onClick={() => registerEmail({ id: email.id })}
                    loading={isLoading}
                    disabled={disabled}
                >Invia</LoadingButton>
            </span>
        </Tooltip>
    )
}

export default RegisterEmailBtn