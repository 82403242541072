import React, { } from 'react';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SpeedIcon from '@mui/icons-material/Speed';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from "notistack";
import {
    useCreateBenchmarkMutation,
} from "../../app/services/appApi";


interface BenchmarkBtnProps extends Omit<React.ComponentProps<typeof Button>, 'variant'> {
    ids: number[];
}

const BenchmarkBtn: React.FC<BenchmarkBtnProps> = ({ ids, ...props }) => {
    const [createBenchmark, { isLoading }] = useCreateBenchmarkMutation();

    const handleCreateBenchmark = async () => {
        if (ids.length === 0) {
            enqueueSnackbar("Seleziona almeno un'email per creare il benchmark", { variant: "warning" });
            return;
        }
        try {
            await createBenchmark({ ids });
            enqueueSnackbar(
                <span>
                    Benchmark created successfully. View it
                    <Link to="/benchmarks" style={{ color: 'inherit', textDecoration: 'underline' }}> here</Link>.
                </span>,
                { variant: "success" }
            );
        } catch (error) {
            enqueueSnackbar("Failed to create benchmark", { variant: "error" });
        }
    };

    return (
        <LoadingButton
            fullWidth
            loading={isLoading}
            color="secondary"
            variant="contained"
            startIcon={<SpeedIcon />}
            disabled={ids.length === 0}
            onClick={handleCreateBenchmark}
            sx={{ mt: 2 }}
            {...props}
        >
            Benchmark
        </LoadingButton>
    );
};

export default BenchmarkBtn;
