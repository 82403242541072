import { useEffect } from "react";
import {
    useDeleteEmailMutation
} from "../../../app/services/appApi";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconBtn } from "../../input/Btn";

interface IDeleteEmailBtnProps {
    emailId: number;
}

export default function DeleteEmailBtn({ emailId }: IDeleteEmailBtnProps) {
    const [deleteEmail, { isLoading, isError, isSuccess }] = useDeleteEmailMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isError) {
            enqueueSnackbar("Impossibile eliminare l'email", { variant: "error" });
        } else if (isSuccess) {
            enqueueSnackbar("Email eliminata", { variant: "success" });
            navigate("/");
        }
    }, [isError, isSuccess, navigate]);


    return (
        <Tooltip title="Delete">
            <div>
                <IconBtn
                    disabled={isLoading}
                    onClick={() => deleteEmail(emailId)}
                    color="error"
                    askConfirm
                    confirmMsg="Sei sicuro di voler eliminare questa email? Non sarà possibile recuperarla."
                >
                    <DeleteIcon />
                </IconBtn>
            </div>
        </Tooltip>
    );
}