import React, { useEffect } from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { enqueueSnackbar } from "notistack";
import {
    useGenerateCsvMutation,
} from "../../../app/services/appApi";

// Common types and utilities
type ButtonVariant = "text" | "outlined" | "contained" | "icon";

interface DownloadCsvBtnProps extends Omit<React.ComponentProps<typeof Button>, 'variant'> {
    ids: number[];
    variant?: ButtonVariant;
}

const downloadCsv = (content: string) => {
    const blob = new Blob([content], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${date.getHours()}${date.getMinutes()}${date.getSeconds()}-export.csv`;
    a.download = formattedDate;
    a.click();
};

// Excel Download Button Component
const DownloadCsvBtn: React.FC<DownloadCsvBtnProps> = ({ ids, variant = "contained", ...props }) => {
    const [generateCsv, { data, isError, isSuccess }] = useGenerateCsvMutation();

    useEffect(() => {
        if (isSuccess && data) {
            downloadCsv(data.content);
            enqueueSnackbar("CSV generato con successo", { variant: "success" });
        } else if (isError) {
            enqueueSnackbar("Errore durante la generazione del CSV", { variant: "error" });
        }
    }, [isSuccess, isError, data]);

    const handleClick = () => generateCsv(ids);

    if (variant === "icon") {
        return (
            <Tooltip title="Scarica CSV">
                <div>
                    <IconButton
                        color="primary"
                        disabled={ids.length === 0}
                        onClick={handleClick}
                        {...props}
                    >
                        <SaveAltIcon />
                    </IconButton>
                </div>
            </Tooltip>
        );
    }

    return (
        <Button
            fullWidth
            variant={variant}
            color="primary"
            startIcon={<SaveAltIcon />}
            disabled={ids.length === 0}
            onClick={handleClick}
            sx={{ mt: 2 }}
            {...props}
        >
            Scarica CSV
        </Button>
    );
};


export default DownloadCsvBtn;