import React from 'react';
import { Control, useFieldArray } from "react-hook-form";
import { Stack } from "@mui/material";
import { EmailDetailAccordion } from "../EmailDetailAccordion";
import { IEmailRecord } from "../emailTypes";
import { ItemActionsToolbar, AddItemButton } from "../toolbar/ItemActions";
import { UISchema } from './dynamicUiTypes';
import FieldRenderer from './FieldRenderer';

interface DynamicEmailFormProps {
    uiSchema: UISchema;
    email: IEmailRecord;
    control: Control<any>;
    disabled?: boolean;
}

const DynamicEmailForm: React.FC<DynamicEmailFormProps> = ({ uiSchema, email, control, disabled }) => {
    const fieldMethods = useFieldArray({
        control,
        name: "items"
    });

    const { fields, append } = fieldMethods;

    const handleAddItem = () => {
        append({});
    };

    // Sort groups by priority
    const sortedGroups = Object.entries(uiSchema).sort((a, b) => b[1].priority - a[1].priority);

    return (
        <>
            {sortedGroups.map(([groupName, group]) => {
                if (groupName === 'items') {
                    // Sort item fields by priority
                    const sortedItemFields = Object.entries(group.fields).sort((a, b) => b[1].priority - a[1].priority);

                    return (
                        <React.Fragment key={groupName}>
                            {fields.map((item, index) => (
                                <EmailDetailAccordion key={item.id} id={`item-${index}`} label={`Riga ${index + 1}`}>
                                    <Stack spacing={2}>
                                        {sortedItemFields.map(([fieldName, fieldSchema]) =>
                                            <FieldRenderer key={fieldName} fieldName={fieldName} fieldSchema={fieldSchema} control={control} index={index} disabled={disabled} />
                                        )}
                                        <ItemActionsToolbar fieldArrayMethods={fieldMethods} index={index} item={item} disabled={disabled} />
                                    </Stack>
                                </EmailDetailAccordion>
                            ))}
                            <AddItemButton onClick={handleAddItem} disabled={disabled} />
                        </React.Fragment>
                    );
                }

                // Sort fields by priority
                const sortedFields = Object.entries(group.fields).sort((a, b) => b[1].priority - a[1].priority);

                return (
                    <EmailDetailAccordion key={groupName} id={groupName} label={group.title} defaultExpanded>
                        <Stack spacing={2}>
                            {sortedFields.map(([fieldName, fieldSchema]) =>
                                <FieldRenderer key={fieldName} fieldName={fieldName} fieldSchema={fieldSchema} control={control} disabled={disabled} />
                            )}
                        </Stack>
                    </EmailDetailAccordion>
                );
            })}
        </>
    );
};

export default DynamicEmailForm;
