import React from 'react';
import { Tooltip, IconButton, Stack, Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { UseFieldArrayReturn } from "react-hook-form";

interface ItemActionsToolbarProps<T> {
    fieldArrayMethods: UseFieldArrayReturn<any, "items", "id">;
    index: number;
    item: T;
    disabled?: boolean;
}

const ItemActionsToolbar: React.FC<ItemActionsToolbarProps<any>> = ({ fieldArrayMethods, index, item, disabled }) => {
    const { fields, move, insert, remove } = fieldArrayMethods;
    return (
        <Stack direction={{ sm: 'column', md: 'row' }} spacing={2} justifyContent="space-around">
            <Tooltip title="Sposta riga su">
                <div>
                    <IconButton disabled={disabled || index === 0} onClick={() => { move(index, index - 1) }}>
                        <ArrowUpwardIcon />
                    </IconButton>
                </div>
            </Tooltip>
            <Tooltip title="Sposta riga giù">
                <div>
                    <IconButton disabled={disabled || index === fields.length - 1} onClick={() => { move(index, index + 1) }}>
                        <ArrowDownwardIcon />
                    </IconButton>
                </div>
            </Tooltip>
            <Tooltip title="Clona riga">
                <div>
                    <IconButton disabled={disabled} onClick={() => { insert(index + 1, { ...item }) }}>
                        <FileCopyIcon />
                    </IconButton >
                </div>
            </Tooltip>
            <Tooltip title="Elimina riga">
                <div>
                    <IconButton disabled={disabled} onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </Tooltip>
        </Stack>
    );
};

interface AddItemButtonProps extends React.ComponentProps<typeof Button> { }

const AddItemButton: React.FC<AddItemButtonProps> = (props) => (
    <Tooltip title="Aggiungi una nuova riga">
        <Button
            startIcon={<AddIcon />}
            sx={{ my: 2 }}
            fullWidth variant="outlined" color="primary" size="small"
            {...props}
        >
            Aggiungi riga
        </Button>
    </Tooltip>
);

export { ItemActionsToolbar, AddItemButton };