import React, { useEffect } from 'react'
import { Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { useCertifyEmailMutation } from "../../app/services/appApi";
import { enqueueSnackbar } from 'notistack';
import { IEmailRecord } from './emailTypes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface CertifyEmailBtnProps extends React.ComponentProps<typeof LoadingButton> {
    email: IEmailRecord
    disable?: boolean
}

function CertifyEmailBtn({ email, disable, ...props }: CertifyEmailBtnProps) {

    const [certifyEmail, { isLoading, isSuccess, isError }] = useCertifyEmailMutation()

    const disabled = !email.is_saved || email.is_certified || disable
    const message = 'Certifica la correzione dei dati'

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar('Dati certificati con successo', { variant: 'success' })
        }
        if (isError) {
            enqueueSnackbar('Errore durante la certifica dei dati', { variant: 'error' })
        }
    }, [isSuccess, isError])


    return (
        <Tooltip title={message}>
            <span>
                <LoadingButton
                    variant='contained'
                    color='primary'
                    startIcon={<CheckCircleIcon />}
                    {...props}
                    onClick={() => certifyEmail({ id: email.id })}
                    loading={isLoading}
                    disabled={disabled}
                >Certifica</LoadingButton>
            </span>
        </Tooltip>
    )
}

export default CertifyEmailBtn