import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IProductRecord, IProductRequest } from "./productTypes";
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetProductQuery, useCreateProductMutation, useUpdateProductMutation } from '../../app/services/appApi';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

interface IProductFormDialogProps {
    productId?: IProductRecord["id"];
    render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing products.
 * It can be opened in two modes:
 * 1. Create mode: productId is not given
 * 2. Edit mode: productId is given
 *
 * In edit mode, the product data is fetched from the API so
 * that the form can be pre-filled with the existing product data.
 */
const ProductFormDialog: React.FC<IProductFormDialogProps> = ({ productId, render }) => {
    const [open, setOpen] = useState(false);
    const createMode: boolean = !productId;

    // Delay data fetching until dialog is opened
    const { data: product } = useGetProductQuery(productId || -1, { skip: createMode || !open });

    const [triggerCreate, {
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        isError: isCreateError

    }] = useCreateProductMutation();
    const [triggerUpdate, {
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError
    }] = useUpdateProductMutation();

    // defaultValues are only available after product data is fetched.
    // If product data is not fetched, but productId is given, show loading indicator
    // If product data is not fetched and productId is not given, show empty form
    const defaultProductValues: IProductRequest | undefined =
        // In create mode, show empty form without fetching product data
        createMode ? { code: "", name: "" } :
            // In edit mode show loading until product data is fetched
            product ?
                // Product is fetched, show product data
                ({
                    name: product.name,
                    code: product.code,
                    // tag_names: product.tags.map(tag => tag.name)
                }) :
                // Product is not fetched
                undefined

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = (data: IProductRequest) => {
        if (createMode) {
            triggerCreate(data);
        } else {
            triggerUpdate({
                id: productId || -1, ...data
            });
        }
    };

    // Handle error and success notifications
    useEffect(() => {
        if (isCreateError) {
            enqueueSnackbar("Errore creazione", { variant: "error" });
        }
        if (isCreateSuccess) {
            enqueueSnackbar("Creato con successo", { variant: "success" });
            handleClose();
        }
        if (isUpdateError) {
            enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
        }
        if (isUpdateSuccess) {
            enqueueSnackbar("Aggiornato con successo", { variant: "success" });
            handleClose();
        }
    }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

    const openButton = render ? render({ onClick: () => setOpen(true) }) : <IconButton onClick={() => setOpen(true)}><EditOutlinedIcon /></IconButton>;

    return (
        <>
            {openButton}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>{createMode ? "Crea nuovo prodotto" : "Modifica prodotto"}</DialogTitle>
                {defaultProductValues ? (
                    <ProductForm
                        createMode={createMode}
                        isLoading={isCreateLoading || isUpdateLoading}
                        onSubmit={handleSubmit}
                        onClose={handleClose}
                        defaultValues={defaultProductValues} />
                ) : (
                    <DialogContent>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}


interface IProductFormProps {
    defaultValues: IProductRequest;
    createMode?: boolean;
    isLoading: boolean;
    onSubmit: (data: IProductRequest) => void;
    onClose: () => void;
}


/**
 * Inner form component for ProductFormDialog.
 * Separate component is used to render only when the
 * defaultValues are available in case of edit mode.
 */
const ProductForm: React.FC<IProductFormProps> = ({ defaultValues, onSubmit, onClose, isLoading, createMode }) => {

    const {
        control,
        handleSubmit,
    } = useForm<IProductRequest>({ defaultValues });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Stack gap={2}>
                    <Controller
                        name="code"
                        rules={{ required: true, minLength: 3 }}
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                autoFocus
                                label={"Codice prodotto"}
                                type="text"
                                fullWidth
                                required
                                error={!!fieldState.error}
                                helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="name"
                        rules={{ required: true, minLength: 3 }}
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                autoFocus
                                label={"Nome prodotto"}
                                type="text"
                                fullWidth
                                required
                                error={!!fieldState.error}
                                helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                                {...field}
                            />
                        )}
                    />


                </Stack>
            </DialogContent>
            <DialogActions sx={{ m: 2 }}>
                <Button
                    startIcon={<CloseOutlinedIcon />}
                    onClick={onClose}>Annulla</Button>
                <LoadingButton
                    startIcon={<CheckOutlinedIcon />}
                    loading={isLoading}
                    disabled={isLoading}
                    variant='contained' type="submit">{createMode ? "Crea" : "Aggiorna"}</LoadingButton>
            </DialogActions>
        </form>


    );
}


export default ProductFormDialog;