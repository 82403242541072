import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { ErrorFallback } from "./features/fallback/ErrorFallback";
import { PostHogProvider } from 'posthog-js/react'

/** Init Sentry error tracking */
if (process.env.REACT_APP_SENTRY_ENABLE === "true" &&
  process.env.REACT_APP_SENTRY_DSN &&
  process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE
) {
  console.log("Initializing Sentry...");
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || "0.0"), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true
    }
  }
}

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </Sentry.ErrorBoundary>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
