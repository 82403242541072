import React, { useEffect, useState, forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from '@mui/material/utils';

export interface IAutocompleteOption {
    id: string;
    option_text: string;
}

export interface IAutocompleteRequest {
    id?: string;
    search?: string;
}

export interface IAutocompleteResponse {
    results: IAutocompleteOption[];
}

interface Props {
    label: string;
    value: string;
    onChange: (value: string) => void;
    lazyQuery: any;
    searchModel: string;
    inputProps?: React.ComponentProps<typeof TextField>;
    disabled?: boolean;
}

const ServerSideSearchSelect2 = forwardRef<HTMLDivElement, Props>(({
    label,
    value,
    onChange,
    lazyQuery,
    searchModel,
    inputProps,
    disabled
}, props) => {
    const [trigger, { data, isFetching }] = lazyQuery();
    const debouncedTrigger = debounce(trigger, 300);
    const [options, setOptions] = useState<IAutocompleteOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<IAutocompleteOption | null>(null);

    // When component mounts, if value is not empty, fetch the selected option
    useEffect(() => {
        if (value && value !== selectedOption?.id) {
            trigger({ searchModel, params: { id: value } });
        }
        else {
            //     console.log("No pre-selected option or selectedOption already set.", value, selectedOption);
        }
    }, []);

    // Update options when data changes
    useEffect(() => {
        if (data) {
            setOptions(data.results);
        }
    }, [data]);

    // When value or options change, update selectedOption
    useEffect(() => {
        if (value === undefined) {
            console.error("ServerSideSearchSelect value is undefined, this should not happen. Check that form is not loading before default values are set.");
        } else if (value === '') {
            // Value is empty, reset selectedOption. This can happen when user clears the input field or the pre-selected option is empty.
            // console.log("Value is empty, reset selectedOption");
            onChange(''); // Make sure the parent component is aware of the change
            setSelectedOption(null);
        } else {
            // Value is set, update selectedOption if necessary
            const foundSelection = options.find((x: IAutocompleteOption) => x.id === value);
            if (foundSelection && selectedOption?.id !== foundSelection.id) {
                // console.log("Selection changed, update", foundSelection);
                setSelectedOption(foundSelection);
                onChange(foundSelection.id); // Make sure the parent component is aware of the change. Without this, validation may not work.
            } else if (!foundSelection && !selectedOption) {
                // This is the case where the pre-selected option is not found (e.g. deleted from the database)
                // console.warn("Pre-selected option not found, this can happen if the option was deleted from the database. Reset value.");
                onChange('');
            } else if (!foundSelection) {
                // This is the case where the user is typing and the selected option is not in the list anymore
                // console.log("Selected option not found in options, this can happen if the user is typing");
            }
        }
    }, [value, options]);

    // Handle user typing in the search box
    const handleInputChange = (_: any, newInputValue: string) => {
        debouncedTrigger({ searchModel, params: { search: newInputValue } });
    };

    // Handle user selecting an option
    const handleChange = (_: any, newValue: IAutocompleteOption | null) => {
        if (newValue) {
            onChange(newValue.id);
        } else {
            onChange('');
        }
    };

    const renderOption = (props: any, option: IAutocompleteOption) => (
        <li {...props} key={`listItem-${option.id}`}>
            {option.option_text}
        </li>
    );

    return (
        <Autocomplete
            value={selectedOption}
            disabled={disabled}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.option_text}
            options={options}
            filterOptions={(x) => x} // Let the server handle filtering
            loading={isFetching}
            onInputChange={handleInputChange}
            onChange={handleChange}
            noOptionsText="Nessun risultato"
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...inputProps}
                    label={label}
                    disabled={disabled || inputProps?.disabled}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isFetching ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
            renderOption={renderOption}
        />
    );
});

export default ServerSideSearchSelect2;